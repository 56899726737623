.loading-wrapper {
    position: absolute;
    z-index: 99;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    svg {
        width: 45px;
        font-size: 45px;
        color: #fffb00;
        z-index: 999;
    }
}