@mixin respond($breakpoint) {
  @if $breakpoint == desktop {
    @media only screen and (min-width: 1024px) {
      @content;
    }
  }
  @if $breakpoint == tablet {
    @media only screen and (min-width: 768px) {
      @content;
    }
  }
}
