@font-face {
  font-family: 'Libre Franklin';
  src: url('~styles/fonts/Libre_Frankin/LibreFranklin-Thin.ttf') format('woff');
  font-weight: 100;
  font-style: normal;
}
@font-face {
  font-family: 'Libre Franklin';
  src: url('~styles/fonts/Libre_Frankin/LibreFranklin-Light.ttf') format('woff');
  font-weight: 300;
  font-style: normal;
}
@font-face {
  font-family: 'Libre Franklin';
  src: url('~styles/fonts/Libre_Frankin/LibreFranklin-Regular.ttf')
    format('woff');
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: 'Libre Franklin';
  src: url('~styles/fonts/Libre_Frankin/LibreFranklin-Medium.ttf')
    format('woff');
  font-weight: 500;
  font-style: normal;
}
@font-face {
  font-family: 'Libre Franklin';
  src: url('~styles/fonts/Libre_Frankin/LibreFranklin-Bold.ttf') format('woff');
  font-weight: 700;
  font-style: normal;
}
@font-face {
  font-family: 'UTM BryantLG';
  src: url('~styles/fonts/UTM-BryantLG/UTM-BryantLG.ttf') format('ttf');
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: 'UTM BryantLG';
  src: url('~styles/fonts/UTM-BryantLG/UTM-BryantLG-Bold.ttf') format('ttf');
  font-weight: 700;
  font-style: normal;
}
